<template>
    <div class="home">
        <div class="top flex">
            <img @click="goHome('')" class="logo" src="@/assets/home2/logo@2x.png" alt="">
            <div class="flex">
                <div class="nav" @click="goHome('#about')">关于赛事</div>
                <div class="nav" @click="goHome('#format')">赛事赛制</div>
                <div class="nav"  @click="goHome('#review')">评审介绍</div>
                <div class="nav" style="color:#45B79C; font-weight: bold">赛事报名</div>
        </div>
    </div>
    <div class="main flex">
        <img class="kv" src="@/assets/sign/kv@2x.png" alt="">
        <div class="right">
            <div class="title">欢迎来到<div class="span1"></div></div>
            <div class="title">物语常熟文化创意设计大赛<div class="span2"></div></div>
             <div class="content">此页面为物语常熟文化创意设计大赛参赛专用，用户需要通过密码登录。您可以在此提交并管理参赛作品。 <br>如果您还没有物语常熟参赛账户，请点击立即注册</div>
             <div @click="goRegistered" class="registered-btn">立即注册</div>
             <div class="line"></div>
             <div class="fs20">
                已有账号,<span style="color:#45B79C;font-weight: bold">请登录!</span>
             </div>
             <!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="活动名称" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
             </el-form> -->
             <div class="fs18">账号<input :class="[error ? 'redborder' : '']" placeholder="请输入您的手机号码" @blur="isPhoneNum" v-model="userName" type="text"></div>
             <div v-if="error" class="error"><i class="el-icon-error"></i> 手机号码不存在/错误</div>
             <div class="fs18">密码<input v-model="password" placeholder="请输入密码" type="password"></div>
             <div @click="goPassword" class="forgot-pass">忘记密码?</div>
                
             <!-- <div class="checkbox-text"><el-checkbox v-model="checked"></el-checkbox>已同意并阅读了<span style="text-decoration:underline; color: #45B79C">《用户隐私条列》</span></div> -->
             <div @click="Login" v-if="!error && password" class="login-btn">登录</div>
             <div v-else class="login-btn no-login-btn">登录</div>

        </div>
    </div>
     <!-- footer -->
    <div id="sign" class="footer">
      <img class="logobottom" src="@/assets/home1/logobottom@2x.png" alt="">
      <div class="text-wrap flex-vcbetween">
        <div>
          <div><span>指导单位</span>中共常熟市委宣传部</div>
          <div><span>主办单位</span>常熟文旅发展有限责任公司</div>
          <div><span>承办单位</span>吉捷文化传媒（上海）有限公司</div>
        </div>
        <div class="flex-vc">
          <div><span>官方热线:</span></div>
          <div>
            <div>邮箱: 962991363@qq.com</div>
            <div>微信: 13817518262</div>
          </div>
        </div>
      </div>
      <div class="text-bottom flex-between">
        <div class="fs13"><img class="c" src="@/assets/home1/C@2x.png" alt="">2021 <span style="font-weight: bold">changshustory.</span> ALL Right Reserved <a style="color:#666" href="https://beian.miit.gov.cn/">沪ICP备12038716号-10</a></div>
        <div class="fs15"><span>关于赛事</span>|<span>赛事赛制</span>|<span style="margin-right:0">评审介绍</span></div>
      </div>
    </div>
    </div>        
</template>
<script>
import { service } from '@/util/api.js'

export default {
    data() {
        return {
            // checked: false,
            error: false,
            userName: '',
            password: ''
        }
    },
    methods: {
        goHome(id){
            // this.$router.push({ name:'Home' ,params:{ id:id } }) 
            this.$router.push({name:'Home',query: {id: id}})
        },
        goRegistered() {
            this.$router.push({path:'/registered'})
        },
        goPassword() {
            this.$router.push({path:'/password'})
        },
        async Login() {
          let parmas = {
            methodName: 'Login',
            userName: this.userName,
            password: this.password
          }
          let res = await service(parmas)
          if(res.Value.State) {
            localStorage.setItem("Token",res.Value.Token)
            this.$router.push({path:'/upload'})
          }else {
            this.$message.error('账号密码错误');
          }
        },
        // goUpload() {
        //     this.$router.push({path:'/upload'})
        // },
        isPhoneNum() {
            this.error = !/^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/.test(this.userName)
        },
        // 获取用户信息
        async GetUserInfo() {
          let parmas = {
            methodName: 'GetUserInfo',
            token: localStorage.getItem("Token")
          }
          let res = await service(parmas)
          if(res.Value) {
            this.$router.push({path:'/upload'})
          }
        },

    },
    mounted() {
      if(localStorage.getItem("Token")) {
        this.GetUserInfo();
      }
    }

}
</script>
<style>
.el-message {
    font-size: 15px;
}
</style>

<style lang="less" scoped>
/deep/.el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: #45B79C;
    border-color: #45B79C;
}
/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #ABACB1;
    font-size: 12px;

}
/deep/.el-checkbox__input {
  margin-right: 6px;
}
/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #45B79C;
}
.flex {
  display: flex;
  align-items: center;
}
.flex-vc {
  display: flex;
  justify-content: center;
}
.flex-vcbetween {
  display: flex;
  // align-items: center;
  justify-content: space-between;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home {
  margin: 0 auto;
  width: 1920px;
  background: #F7F7F7;

  .top {
    background: #FFFFFF;
    height: 134px;
    .logo {
      width: 200px;
      margin-left: 360px;
      margin-right: 328.5px;
      cursor: pointer;
    }
    .nav:hover {
      color:#45B79C;
      font-weight: bold;
      cursor: pointer
    }
    .nav {
      width: 175px;
      font-size: 20px;
      position: relative;
      a {
        text-decoration: none;
        color: #333333;
      }
      span {
        position: absolute;
        left: 49.5px;
        top: 15px;
        display: block;
        width: 76px;
        height: 10px;
        background: #AAAAAA;
        z-index: -1;
      }
    }
  }
.main {
    width: 1200px;
    margin: 38px auto;
    background: #FFFFFF;
    box-shadow: 0 4px 12px 4px rgba(0,0,0,0.10);
    border-radius: 10px;
    .kv {
       width: 526px; 
    }
    .right {
        padding: 35px;
        .title {
            font-size: 44px;
            line-height: 62px;
            color: #45B79C;
            text-align: left;
            font-weight: bold;
            position: relative;
            z-index: 0;
            .span1 {
                width: 172px;
                height: 24px;
                background: #EEFDF9;
                position: absolute;
                top: 36px;
                z-index: -1;
            }
            .span2 {
                width: 528px;
                height: 24px;
                background: #EEFDF9;
                position: absolute;
                top: 36px;
                z-index: -1; 
            }
        }
        .content {
            font-size: 22px;
            color: #666666;
            text-align: left;
            line-height: 36px;
            margin: 17px 0;
        }
        .registered-btn {
            width: 150px;
            line-height: 46px;
            background: #45B79C;
            font-size: 18px;
            color: #FFFFFF;
        }
        .line {
            margin: 17px 0 22px 0;
            width: 621px;
            height: 1px;
            background: #E5E5E5
        }
        .fs20 {
            font-size: 20px;
            color: #333333;
            text-align: left;
            margin-bottom: 34px;
        }
        .fs18 {
            font-size: 18px;
            color: #231815;
            input {
                width: 274px;
                height: 46px;
                border: 1px solid #DCDCDC;
                margin: 12px 30px;
                padding: 0 24px;
                font-size: 18px;
            }
            input::placeholder {
                color: #BFBFBF;
            }
            .redborder {
                border: 1px solid #F54531;
            }
        }
        .error {
          font-size: 12px;
          color: #F54531;
          text-align: left;
          margin-left: 190px;
        }
        .forgot-pass {
            margin: 0 auto;
            width: 360px;
            font-size: 18px;
            color: #ABACB1;
            text-align: right;
        }
        .checkbox-text {
            font-size: 12px;
            color: #ABACB1;
            margin: 21px 0 14px 0;
        }

        .login-btn {
            width: 322px;
            line-height: 46px;
            background: #45B79C;
            font-size: 18px;
            color: #FFFFFF;
            margin-left: 170px;
            margin-top: 21px;
        }
        .no-login-btn {
          opacity: 0.5;
        }
    }
}
.footer {
    width: 1920px;
    height: 170px;
    background: #C1C1C1;
    position: relative;
    .logobottom {
      position: absolute;
      top: 38px;
      left: 860px;
      width: 200px;
    }
    .text-wrap {
      width: 1170px;
      margin: 0 auto ;
      color: #666666;
      font-size: 13px;
      line-height: 32px;
      text-align: left;
      padding-top: 30px;
      span {
        font-weight: bold;
        font-size: 15px;
        // color: #FFFFFF;
        margin-right: 16px;
        display: inline-block;
      }
    }
    .text-bottom {
      width: 1170px;
      margin: 0 auto;
      line-height: 32px;
      .fs13 {
        font-size: 13px;
        color: #666;
        img {
          width: 10px;
        }
      }
      .fs15 {
        font-size: 15px;
        color: #ABACB1;
        span {
          color: #666666;
          margin: 0 26px;
          display: inline-block;
        }
      }
    }

}
}
</style>


    
    