import Vue from 'vue';

import axios from 'axios';


export const get = (url, data = {}) => {
    // Spin.show(); // 显示加载框
    return new Promise((resolve, reject) => {
        axios
            .get(url, {params: data})
            .then(response => {
                // Spin.hide(); // 隐藏加载框
                resolve(response.data);
            })
            .catch(err => {
                // Spin.hide(); // 隐藏加载框
                Toast(err.response.data.Message);
                reject(err);
            });
    });
};

export const post = (url, data = {}) => {
    // Spin.show(); // 显示加载框
    return new Promise((resolve, reject) => {
        axios
            .post(url, data)
            .then(response => {
                // Spin.hide(); // 隐藏加载框
                resolve(response.data);
            })
            .catch(err => {
                Toast(err.response.data.Message);
                reject(err);
            });
    });
};

export const postParams = (url, params = {}) => {
    // Spin.show(); // 显示加载框
    return new Promise((resolve, reject) => {
        axios({
            url: url,  //请求路径（接口）
            method: 'POST', //请求方式
            params: params,
        })
            .then(response => {
                // Spin.hide(); // 隐藏加载框
                resolve(response.data);
            })
            .catch(err => {
                // Spin.hide(); // 隐藏加载框
                Toast(err.response.data.Message);
                reject(err);
            });
    });
};